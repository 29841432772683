<template>
  <div class="container">
    <div class="row">
      <div class="col-md-6 mb-3">
        <DatePicker :modelValue="start*1000" @update:modelValue="update('start',$event)" :attributes='attr' title-position="left" mode="dateTime" timezone='UTC'
          is24hr @drag="dragValue = $event" :model-config="modelConfig">
          <template v-slot="{ togglePopover }">
            <div class="input-group" @click="togglePopover">
              <button type="button" class="btn btn-secondary">
                <IconCalendar class="d-block"/>
              </button>
              <span class="input-group-text">Start</span>
              <output
                class="form-control"
                :value="format(start)"
              />
            </div>
          </template>
        </DatePicker>
      </div>
      <div class="col-md-6 mb-3">
        <DatePicker :modelValue="end*1000" @update:modelValue="update('end',$event)" :attributes='attr' title-position="left" mode="dateTime" timezone='UTC'
          is24hr :model-config="modelConfig">
          <template v-slot="{ togglePopover }">
            <div class="input-group" @click="togglePopover">
              <button type="button" class="btn btn-secondary">
                <IconCalendar class="d-block"/>
              </button>
              <span class="input-group-text">End</span>
              <output
                class="form-control"
                :value="format(end)"
              />
            </div>
          </template>
        </DatePicker>
      </div>
    </div>
  </div>
</template>
<style type="text/css" scoped>
  :deep(.vc-popover-content) {
    box-sizing: border-box;
    max-width: 100vw;
  }
  :deep(.vc-time-picker)  {
    justify-content: center;
  }
</style>
<script>
  import 'v-calendar/dist/style.css';
  import { DatePicker } from 'v-calendar';
  import IconCalendar from '@/components/icons/IconCalendar';
  const dateFormater = new Intl.DateTimeFormat([],{
    timeZone:'UTC',
    dateStyle:'medium',
    timeStyle:'long',
    hourCycle:'h23',
  });
  export default {
    name:'CustomDatePicker',
    components:{
      IconCalendar,
      DatePicker
    },
    watch:{
    },
    computed:{
      modelConfig:()=>({
        type: 'number'
      }),
      start() {
        return this.value.start ? this.value.start : null;
      },
      end() {
        return this.value.end ? this.value.end : null;
      },
      range(){
        const start = this.start || this.end;
        const end = this.end || this.start;
        if (!start && !end)
          return null;
        return {
          start:start*1000,
          end:end*1000
        };
      },
      attr(){
        return [
          {
            key: 'today',
            highlight: {
              color: 'purple',
              fillMode: 'light',
            },
            dates: new Date(),
          },
          {
            key:'range',
            highlight:{
              color: 'blue',
              fillMode: 'solid'
            },
            dates: this.range
          }
        ]
      }
    },
    methods:{
      format(date){
        const base = new Date(0);
        base.setUTCSeconds(date);
        return date?dateFormater.format(base):null
      },
      update(key, val){
        const out = {...this.value};
        out[key] = Math.floor(val/60000)*60; //Flatten to minutes
        const {start,end} = out;
        if (start&&end&&end<start)
          this.$emit('update:value',{
            start:end,
            end:start
          });
        else
          this.$emit('update:value',out);
      },
      testClick(event){
        event.target.blur();
      }
    },
    props:{
      value:Object
    },
    emits: ['update:value'],
  }
</script>
