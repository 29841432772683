<template>
  <div class="row">
    <h3>Faction Settings
      <button type="button" class="btn btn-warning float-end" v-if="!loading && code === 404" @click="forceFetchData">Fetch Account</button>
      <button type="button" class="btn btn-primary float-end" v-if="!loading && code === 200" @click="fetchData">Reload Settings</button>
    </h3>
    <span class="text-muted" v-if="settings?.name">{{settings.name}}<template v-if="settings?.fid"> [{{settings.fid}}]</template></span>
  </div>
  <hr class="border-light my-3">
  <div class="d-flex justify-content-center" v-if="loading">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <template v-else>
    <div class="alert" :class="msg?.error ? 'alert-danger' : 'alert-success'" v-if="msg?.error || msg?.success">{{msg?.error?.message}}{{msg?.success}}</div>
    <FactionSettingsMenu v-model="settings" @mySubmit="saveFactionSettings" />
  </template>
</template>
<script>
  import fetch from '@/fetch.js';
  //import { DatePicker } from 'v-calendar';
  import FactionSettingsMenu from './FactionSettingsMenu';
  //import CustomDatePicker from '@/components/CustomDatePicker';
  export default {
    components:{
      //CustomDatePicker,
      FactionSettingsMenu
      //DatePicker
    },
    data () {
      return {
        loading: false,
        settings: {},
        msg: null,
        code:0,
        dragValue: null,
        chain:null,
        drug:null,
        train:null,
      }
    },
    created () {
      // fetch the data when the view is created and the data is
      // already being observed
      this.fetchData();
    },
    watch: {
      // call again the method if the route changes
      /* No i will not
      '$route'(newRoute) {
        console.log(newRoute);
      }
      */
    },
    methods: {
      parseData(data){
        const {fid,discord_inv} = data;
        this.$store.commit('setBulk',{fid,discord_inv});
        this.settings.chain= {
          start: data?.chain_start,
          end: data?.chain_end
        };
        this.settings.drug = {
          start: data?.drug_start,
          end: data?.drug_end
        };
        this.settings.train = {
          start: data?.train_start,
          end: data?.train_end
        };
        ['daily_require','discord_inv','fid','leader','name','relax']
          .forEach(key=>this.settings[key] = data[key]);
      },
      async fetchData () {
        this.settings = {};
        this.code = this.msg = null;
        this.loading = true;
        fetch('/api/v1/settings/faction',{
          method:'get'
        }).then(data=>{
          if ('code' in data)
            this.code = data.code;
          if ('error' in data) {
            throw new Error(data.error);
          }
          this.parseData(data);
        }).catch(error=>{
          this.msg = {error};
        }).finally(()=>this.loading = false);
        // replace `getPost` with your data fetching util / API wrapper
      },
      async forceFetchData () {
        this.code = this.msg = null;
        this.loading = true;
        fetch('/api/v1/settings/faction',{
          method:'put'
        }).then(data=>{
          if ('code' in data)
            this.code = data.code;
          if ('error' in data) {
            //if (data.code == 404)
            throw new Error(data.error);
          }
          this.parseData(data);
        }).catch(error=>{
          this.msg = {error};
        }).finally(()=>this.loading = false);
        // replace `getPost` with your data fetching util / API wrapper
      },
      async saveFactionSettings(event) {
        const buttonClass = event.submitter.classList;
        buttonClass.add('btn-secondary');
        buttonClass.remove('btn-primary');
        this.code = this.msg = null;
        fetch('/api/v1/settings/faction',{
          method:'post',
          data:this.settings
        }).then(data=>{
          if ('code' in data)
            this.code = data.code;
          if ('error' in data) {
            throw new Error(data.error);
          }
          this.msg = {
            success:'Settings successfully saved.'
          }
        }).catch(error=>{
          this.msg = {error};
        }).finally(()=>{
          buttonClass.remove('btn-secondary');
          buttonClass.add('btn-primary');
        });
      }
    }
  }
</script>
