<template>
  <form @submit.prevent="saveFactionSettings">
    <input :value="modelValue?.fid" hidden/>
    <h4>General Settings</h4>
    <div class="container">
      <hr class="border-light my-3">
      <div class="row mb-3">
        <label class="form-label">Discord Invite</label>
        <div class="input-group mb-3">
          <span class="input-group-text">
            https://discord.gg/
          </span>
          <input class="form-control"  :maxlength="DISC_MAX_LEN" name="discord_inv"
            :value="modelValue?.discord_inv" @paste="onDiscordPaste" @input="onChange" />
        </div>
        <div class="form-text">
          Your faction's discord invite link. (Please do not use
          <a href="https://support.discord.com/hc/en-us/articles/115001542132-Server-Vanity-URLs" target="_blank">Vanity URLs</a>
          or custom urls).
          <a :href="testDiscordLink" target="_blank">Test your link</a>
        </div>
      </div>
    </div>
    <h4>Chain Settings</h4>
    <div class="container">
      <hr class="border-light my-3">
      <div class="row mb-3">
        <label class="form-label">Chain Date</label>
        <CustomDatePicker :value="modelValue?.chain" @update:value="customDateUpdate('chain',$event)" />
        <div class="form-text">
          The chain start/end date+time
        </div>
      </div>
      <div class="row mb-3">
        <label class="form-label">Armory Date</label>
        <CustomDatePicker :value="modelValue?.drug" @update:value="customDateUpdate('drug',$event)" />
        <div class="form-text">
          The start/end date+time when drugs and points are used from the faction
        </div>
      </div>
      <div class="row mb-3">
        <label class="form-label">Daily Require</label>
        <input :value="modelValue?.daily_require" @input="onChange" name="daily_require" />
        <div class="form-text">
          The amount of required hits per day (to automatically calculate the total required hits).
        </div>
      </div>
      <div class="row mb-3">
        <label class="form-label">Relax Hits</label>
        <input :value="modelValue?.relax" @input="onChange" name="relax" />
        <div class="form-text">
          The amount of hits to ignore from the total required hits (to relax the requirement).
        </div>
      </div>
    </div>
    <h4>Train Settings</h4>
    <div class="container">
      <div class="row mb-3">
        <label class="form-label">Train Date</label>
        <CustomDatePicker :value="modelValue?.train" @update:value="customDateUpdate('train',$event)" />
        <div class="form-text">
          The start/end date+time when faction members are training
        </div>
      </div>
    </div>


    <hr class="border-light my-3">
    <!--SUBMIT DATA-->
    <div class="row mb-3 float-end">
      <div class="col-auto mb-1">
        <input type="submit" class="btn btn-primary" value="Save Data"/>
      </div>
    </div>
  </form>
</template>
<script>
  import CustomDatePicker from '@/components/CustomDatePicker';

  export default {
    components:{
      CustomDatePicker,
      //DatePicker
    },
    props:{
      modelValue:Object
    },
    created () {
    },
    computed:{
      DISC_MAX_LEN:()=>10,
      testDiscordLink() {
        const url = new URL('https://discord.gg/')
        url.pathname = '/' + this.modelValue?.discord_inv;
        return url.toString();
      }
    },
    watch: {
    },
    methods: {
      onDiscordPaste(event) {
        const paste = (event.clipboardData || window.clipboardData).getData('text');
        let output = '';
        try {
          const url = (new URL(paste)).pathname.split('/');
          output = url[url.length-1].slice(this.DISC_MAX_LEN*-1);
        } catch (e) {
          output = paste.slice(this.DISC_MAX_LEN*-1);
        }
        event.target.value = output;
        this.onChange(event);
        event.preventDefault();
      },
      customDateUpdate(name,value){
        this.onChange({target:{name,value}});
      },
      onChange(event){
        const dupe = {...this.modelValue};
        const {name,value} = event.target;
        dupe[name] = value;
        this.$emit('update:modelValue', dupe);
      },
      async saveFactionSettings(event) {
        this.$emit('mySubmit',event);
      }
    },
    emits:['mySubmit','update:modelValue']
  }
</script>
